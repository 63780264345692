<template>

    <SpaceBuilder
    />

</template>

<script>

import SpaceMenu from "@/components/space/SpaceMenu.vue";

import SpaceBuilder from "@/components/space/SpaceBuilder.vue";
export default {
    name: "FileView.vue",
    components: {SpaceBuilder, SpaceMenu,}
    }
</script>

<style lang="scss">

.file{

    .file_open{
        $width: 50px;
        @include assignValue(width max-width min-width, $width);
        text-align: center;
    }

    .file_reissue{
        $width: 65px;
        @include assignValue(width max-width min-width, $width);
        text-align: center;
    }

    .file_is_issued{
        $width: 25px;
        @include assignValue(width max-width min-width, $width);
        text-align: center;
        padding: 3px 0 1px;
    }

    .date{
        width: 80px;
        min-width: 80px;
        max-width: 80px;
    }

    .file_name{
        width: 100px;
        min-width: 100px;
        max-width: 100px;
    }

    .file_file_type_id{
        $width: 80px;
        @include assignValue(width max-width min-width, $width);
    }

    .file_vs{
        $width: 100px;
        @include assignValue(width max-width min-width, $width);
    }

    .file_ks{
        $width: 50px;
        @include assignValue(width max-width min-width, $width);
    }

    .file_ss{
        $width: 25px;
        @include assignValue(width max-width min-width, $width);
    }

    .file_drive_id{
        $width: 270px;
        @include assignValue(width max-width min-width, $width);
    }

}

</style>
