import ApiRouter from "@/app/ApiRouter";
import $ from "jquery";

export default{
    install: (app)=>{

        /**
         * @class
         * @name Capi
         * @returns {Promise}
         */
        class Capi{
            /**
             *
             * @param attrs
             * @param data
             * @return {Promise<string|object|array>}
             */
            get(attrs, data = null){
                return new Promise((resolve, reject)=>{
                    let pack = app.utilities.createPackObject(attrs, data);
                    let url = ApiRouter.get({
                        ...attrs,
                        path: !attrs['path'] ? 'get' : attrs['path']
                    });

                    setTimeout(()=>{
                        $.ajax({
                            url: url,
                            data: pack,
                            dataType: 'json',
                            type: 'GET',
                            accept: "application/json; charset=utf-8",
                            headers:{
                                Accept: "application/json; charset=utf-8",
                            },
                            xhrFields: {
                                withCredentials: true
                            },
                            crossDomain: true,
                            success: function (data) {
                                resolve(data.message)
                            },
                            error: function ({responseJSON: error, readyState}) {
                                if(!error) error = {}
                                if(readyState === 0) error.code = 0
                                reject(error);
                            }
                        });
                    }, 0)


                })
            }

            /**
             * PUT data to API (update)
             * @dynamic
             * @const
             * @param attrs
             * @param updateData
             * @property response.responseJSON
             * @return Promise
             */
            update(attrs, updateData = null){
                return new Promise((resolve, reject) => {
                    let pack = app.utilities.createPackObject(attrs, updateData);
                    let url = ApiRouter.get(
                        {
                            ...attrs,
                            path: !attrs['path'] ? 'update' : attrs['path']
                        }
                    );

                    $.ajax({
                        url: url,
                        data: pack,
                        dataType: 'json',
                        type: 'PUT',
                        xhrFields: {
                            withCredentials: true
                        },
                        crossDomain: true,
                        success: function (data) {
                            resolve(data.message);
                        },
                        error: function ({responseJSON: error, readyState}) {
                            if(!error) error = {}
                            if(readyState === 0) error.code = 0
                            reject(error);
                        }
                    });
                });
            }

            /**
             * POST data to API (create)
             * @param attrs
             * @param data
             * @property response.responseJSON
             * @return Promise
             */
            create(attrs, data= null){
                return new Promise((resolve, reject) => {
                    let pack = app.utilities.createPackObject(attrs, data);
                    let url = ApiRouter.get(
                        {
                            ...attrs,
                            path: !attrs['path']? 'create' : attrs['path']
                        }
                    );

                    $.ajax({
                        url: url,
                        data: pack,
                        dataType: 'json',
                        type: 'POST',
                        xhrFields: {
                            withCredentials: true
                        },
                        crossDomain: true,
                        success: function (response) {
                            resolve(response.message);
                        },
                        error: function ({responseJSON: error, readyState}) {
                            if(!error) error = {}
                            if(readyState === 0) error.code = 0
                            reject(error);
                        }
                    });
                });

            }

            /**
             * @dynamic
             * @const
             * @param attrs
             * @param updateData
             * @property response.responseJSON
             * @return Promise
             */
            delete(attrs, updateData = null){
                return new Promise((resolve, reject) => {
                    let pack = app.utilities.createPackObject(attrs, updateData);
                    let url = ApiRouter.get(
                        {
                            ...attrs,
                            path: !attrs['path'] ? 'delete' : attrs['path']
                        }
                    );

                    $.ajax({
                        url: url,
                        data: pack,
                        dataType: 'json',
                        type: 'DELETE',
                        xhrFields: {
                            withCredentials: true
                        },
                        crossDomain: true,
                        success: function (data) {
                            resolve(data.message);
                        },
                        error: function ({responseJSON: error, readyState}) {
                            if(!error) error = {}
                            if(readyState === 0) error.code = 0
                            reject(error);
                        }
                    });
                });
            }

        }

        app.capi = new Capi;
        app.provide('Capi', new Capi)
    }
}
