<template>

    <div><img class="logo" src="@/assets/img/logo/capptn-logo-s-textem-white-vertical-220px.png" alt="Capp.tn logo"></div>

    <template v-if="cookiesLogin">
        <LoadingDots/>
    </template>
    <template v-else>
        <div class="login-form">
            <input type="text" v-model="data.user_mail" @keydown.enter="login">
            <input type="password" v-model="data.user_password" @keydown.enter="login">
            <button @click="login">
                <template v-if="!loginInProgress">Login</template>
                <LoadingDots v-else/>
            </button>
        </div>

        <hr>

        <GoogleLogin :callback="googleLogIn"/>
    </template>

    <div v-if="error" class="error-label">
        <span>{{ error }}</span>
    </div>

</template>

<script>
import {inject, onMounted, onUnmounted, reactive, ref} from 'vue';
import Utilities from "@/plugins/Utilities";
import LoadingDots from "@/components/waiter/LoadingDots.vue";
import Button from "@/components/space/grid/Selector";
import {decodeCredential, googleOneTap, googleSdkLoaded, googleTokenLogin} from "vue3-google-login";
export default {
    name: 'Login',
    components: {Button, LoadingDots},
    props: [
        'dialog',
        'username',
        'password',
        'showSelectCrew',
        'userData'
    ],
    setup(props){
        /** @type {SmartEnv} */
        const env = inject('env')

        /** @type {Dialog} */
        const dialog = inject('Dialog')

        const wait = ref()
        const loginInProgress = ref()

        const data = reactive({
            user_mail: undefined,
            user_password: undefined
        })

        let error = ref()

        let cookiesLogin = ref(false)

        function login(){
            if((data.user_mail===undefined || data.user_mail==='') && (data.user_password===undefined || data.user_password==='')) {
                error.value = 'Please fill username and password'
                return
            }
            if(data.user_password===undefined || data.user_password==='') {
                error.value = 'Cannot use empty password'
                return
            }
            if(data.user_mail===undefined || data.user_mail==='') {
                error.value = 'Cannot use empty username'
                return
            }

            loginInProgress.value = true
            error.value = null

            userService.login(data)
                .then((response)=>{
                    assignUserData(response)
                })
                .catch((e)=>{
                    error.value = e?.message?e.message:e.code!==undefined&&e.code===0?'Internet connection error':'Unknown error'
                    loginInProgress.value = false
                })
        }

        function googleLogIn(response){
            wait.value = true
            error.value = null
            // This promise is resolved when user selects an account from the the One Tap prompt
            const userData = decodeCredential(response.credential)

            userService.googleUserLogin({
                user_mail: userData.email,
                user_google_id: userData.sub,
                user_name: userData.name
            })
                .then((data)=>{
                    assignUserData(data)
                })
                .catch((e)=>{
                    console.log('e', e)
                    error.value = e.message
                })
        }

        function assignUserData(data){
            if(data['user']['crews'].length===1) env.crew_id = data['user']['crews'][0]['crew_id'] // case whe user has one crew
            env.login = data;
        }

        /** @type UserService */
        const userService = inject('UserService')

        /** @type Utilities */
        const Utilities = inject('Utilities')


        onUnmounted(()=> {

        })

        onMounted(() => {
            if(!env.logout){
                cookiesLogin.value = true;
                userService.cookiesLogin()
                    .then((response)=>{
                        assignUserData(response)
                    })
                    .catch((e)=>{
                        dialog.nonCritical(e)
                        cookiesLogin.value = false;
                        addOneTapLogin()
                    })
            }
            else{
                addOneTapLogin()
            }
        })

        function addOneTapLogin(){
            googleOneTap({ autoLogin: false })
                .then((response) => {
                    // This promise is resolved when user selects an account from the the One Tap prompt
                    googleLogIn(response)
                })
                .catch((e)=>{
                    console.log('e', e)
                    error.value = e.message
                })
        }

        const selectCrew = (crewId) => {
            Utilities.setCookie('crew_id', crewId);
        }

        return {
            googleLogIn,
            env,
            selectCrew,
            wait,
            error,
            data,
            login,
            loginInProgress,
            cookiesLogin
        }
    }
}
</script>

<style scoped lang="scss">

@import "@/assets/scss/_btn.scss";
@import "@/assets/scss/_colors.scss";

.logo{
    margin: 15px;
    /*height: 100px;
    width: auto;*/
}

.login-form{
    margin-top: 10px;
    display: flex;
    flex-flow: column;
    align-items: center;

    button, input{
        padding: 6px 6px 4px;
    }

    input{
        height: 25px;
        width: 200px;
        color: $darkGray;
        margin-top: 5px;
        font-weight: lighter;
        background-color: $whiteGray;
        font-size: 12px;
        border-color: $goldOrange;

        &:focus,
        &:focus-visible,
        &:-webkit-autofill,
        &:-webkit-autofill:focus,
        &:-webkit-autofill:focus-visible{
            border-color: $goldOrange;
            font-weight: lighter;
            background-color: $whiteGray!important;
            font-size: 12px;
            //-webkit-background-clip: text;
            -webkit-text-fill-color: $darkGray;
            -webkit-box-shadow:0 0 0 50px $whiteGray inset;
        }
    }

    button{
        @extend .btn__dark;
        color: $goldOrange;
        font-weight: normal;
        margin-top: 10px;
        width: 150px;
        height: 40px;
        font-size: 18px;
        margin-left: 0;
        margin-right: 0;
        &:hover:not(:disabled){
            background-color: #3b3b3b;
        }
    }
}

hr{
    margin-top: 30px;
    margin-bottom: 20px;
}

.error-label{
    margin: 10px 0 0;
}

</style>
