<template>

    <div class="header" v-if="env.configuration">
        <BaseHeader/>
    </div>

    <div class="content">
        <router-view v-if="env.configuration && !env.loadingConfiguration"/>
    </div>

    <DialogBase
        v-if="env.dialog"
        :dialog="env.dialog"
        @close="env.dialog = null"
    />

    <BaseWaiter v-if="env && env.waiter"/>
    <Datalists v-if="env.configuration"/>
    <Widgets v-if="utilities.isThereAnyOpenTracker()||env.obligations.length"/>

</template>

<script>

import {defineAsyncComponent, inject, onMounted, reactive, ref, watch, watchEffect} from "vue";
import Widgets from "@/components/tools/Widgets.vue";

export default {
    name: 'App',
    components: {
        Widgets,
        Datalists: defineAsyncComponent(() => import('@/components/main/Datalists')),
        Trackers: defineAsyncComponent(() => import('@/components/tools/Trackers.vue'))
    },
    props: {

    },
    setup(props){

        /** @type {SmartEnv}*/
        let env = inject('env');

        /** @type Dialog */
        let dialog = inject('Dialog');

        /** @type Google */
        let google = inject('Google');

        /** @type Waiter */
        let waiter = inject('Waiter')

        let configurationService = inject('ConfigurationService')

        let utilities = inject('Utilities')

        let show = ref(null)

        function loadConfiguration(){
            if(!env.login) return;
            if(!env.crew_id) return;
            //console.log('user', env.login.user)
            //console.log('member', env.login.user['member_id'])
            env.loadingConfiguration = true
            waiter.show('Loading configuration')
            configurationService.loadGlobal()
                .then((data)=>{
                    waiter.hide()
                    env.configuration = data;
                    env.login.user = data.user
                    //console.log('consfiguration', env.configuration)
                    //console.log('member', env.login.user['member_id'])
                    env.loadingConfiguration = false
                })
                .catch(error => {
                    waiter.hide()
                    //console.log(error)
                    dialog.error(error)
                    env.loadingConfiguration = true
                });
        }

        watch(() => env.crew_id, ()=>{
            utilities.resetEnvTempVariable()
            loadConfiguration()
        })


        watchEffect(()=>{

            // User is not logged in
            if(!env.login){
                dialog.login()
                return;
            }

            // Crew is not selected
            if(!env.crew_id){
                dialog.selectCrew()
                return
            }

            utilities.setCookie('crew_id', env.crew_id);

            // USer is logged in and crew is selected
            dialog.close()
            //loadConfiguration()

        })

        return {env, show, utilities};

    }

}
</script>

<style lang="scss">

@import "@/assets/scss/_btn.scss";

body {
    font-weight: normal;
    font-size: 12px;
    background-color: cadetblue;
    color: white;
    margin: 0;
}

#app {
    font-family: Segoe, "Segoe UI", "DejaVu Sans", "Trebuchet MS", Verdana, "sans-serif";
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #f0f0f0;
    display: flex;
    flex-flow: column;
    width: 2650px;
    position: relative;
}

button{
    @extend .btn__base;
}

.header{
    padding: 15px;
    background-color: #262626;
    flex: 0 1 auto;
}

.content{
    display: flex;
    flex-flow: column wrap;
    justify-content: center;
    align-items: center;

    .menu{
        width: 100%;
    }
}

input{
    background-color: transparent;
    border: solid 1px #f0f0f0;
    border-radius: 3px;
    padding-left: 3px;
    color: #212121;
    height: 18px;
}

select{
    border-radius: 3px;
}

input:focus,
input:focus-visible,
input:-webkit-autofill,
input:-webkit-autofill:focus,
input:-webkit-autofill:focus-visible
/*select:focus,
select:focus-visible,
option:focus,
option:focus-visible*/{
    background-color: #e0e0e0!important;
    border-color: darkorange;
    outline-style: none;
    /*font-weight: bold;*/
    /*background-color: transparent!important;*/
    /*-webkit-background-clip: text;*/
    -webkit-text-fill-color: #2c2c2c;
}

table td, table th{
    border: 1px solid #f0f0f0;
    padding: 1px 3px;
}

table th{
    background-color: #3f3f3f;
}

.error-label,
.alert-label,
.success-label,
.no-data{
    margin-bottom: 20px;
    display: flex;
    justify-content: center;

    span{
        font-weight: bold;
        padding: 3px;
        border-radius: 3px;
    }
}

.error-label{
    span{
        color: #f0f0f0;
        background-color: crimson;
    }
}

.no-data{
    span{
        background-color: $lightGray;
        color: #2c2c2c;
    }
}

.alert-label{
    margin-bottom: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 3px;
    span{
        background-color: gold;
        color: $lighterGray;
    }
}

.success-label{
    margin-bottom: 0;
    span{
        color: #f0f0f0;
        background-color: #00b050;
    }
}

a{
    color: #f0f0f0;
    &:hover{
        color: darken(#f0f0f0, 5)
    }
}

.toggle-timer-button{
    &:hover{
        cursor: pointer;
    }
}

.space,
.tasks{
    .add-button,
    .clear-button{

    }

    .clear-button{

    }
}

.manager,
.dialog{
    .inner{
        .data-tab{
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: flex-start;
            margin-left: auto;
            margin-right: auto;
            border-radius: 3px;

            .data{
                display: flex;
                flex-flow: column;
                //width: fit-content;
                height: fit-content;
                //min-height: 150px;
                max-height: 60vh;
                overflow-y: auto;
                position: relative;
                padding-bottom: 5px;
                box-sizing: content-box;

                .space {
                    //height: fit-content;
                    margin: 0 auto;
                }

                &.empty{
                    padding-bottom: 0;
                }

            }

            .btn{
                margin: 0;
                border-bottom-right-radius: 0;
                border-bottom-left-radius: 0;
                padding: 5px;
                color: #f0f0f0;
            }

            .project-transaction,
            .activity{
                margin: 0 auto;
            }

            .error-label,
            .no-data{
                margin-top: 10px;
                margin-bottom: 0;
            }

        }

    }
}

.data-tabs{
    margin: 10px 0;

    .btn{
        margin: 0;
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 0;
        padding: 5px;
        color: #f0f0f0;

        &.expenses{
            background-color: $red;
            &:hover:not(:disabled){
                background-color: lighten($red, 10);
            }
        }

        &.incomes{
            background-color: #00b050;
            &:hover:not(:disabled){
                background-color: lighten(#00b050, 3);
            }
        }

        &.activities{
            color: $darkGray;
            background-color: $whiteGray;
            &:hover:not(:disabled){
                background-color: lighten($whiteGray, 10);
            }
        }
    }
}


</style>
