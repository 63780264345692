import {createRouter, createWebHistory} from 'vue-router'
import BaseView from "@/components/view/BaseView.vue";
import ThreadView from "@/components/view/ThreadView.vue";

const routes = [
    {
        path: '/',
        redirect: '/home'
    },
    {
        path: '/:service',
        name: 'service',
        component: BaseView,
        children: [{
            path: '/:service/:space',
            name: 'space',
            component: BaseView,
            props: true
        }],
        props: true
    },
    {
        path: '/thread/:id',
        name: 'comment',
        component: ThreadView,
        props: route => ({
            attrs:{
                directCommentLink: true,
                service: 'thread',
                space: 'thread',
                id: Number(route.params.id), // Pull `id` from route params
            }

        })
    },
    {
        path: '/:catchAll(.*)',
        redirect: '/'
    }
]

const appRouter = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

export default appRouter

