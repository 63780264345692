<script setup>

import DialogBase from "@/components/dialog/DialogBase.vue";
import Prompt from "@/components/dialog/Prompt.vue";
import SpaceBuilder from "@/components/space/SpaceBuilder.vue";
import _ from 'lodash';
import {inject, ref, toRaw} from "vue";


/** @type Utilities */
const utilities = inject('Utilities')
const props = defineProps(['invoicingDialog'])
const emit = defineEmits(['close'])
const files =  _.cloneDeep(props.invoicingDialog.files)
const invoices = props.invoicingDialog.invoices
const proformas = props.invoicingDialog.proformas
const type = props.invoicingDialog.documentType

let message = ref()
let question = ref()
let pairOn = ref(false)
let parentFile = ref()
let error = ref()

if(invoices && invoices.length && proformas && proformas.length){

    message.value = 'There are already issued invoice(s) and proforma(s) associated with this transaction.'
    if(type === 'invoice'){
        question.value = `
                Do you want to pair your new invoice with any proforma? <br>
                If so, select desired proforma first.`
        pairOn.value = true
    }else if(type === 'proforma'){
        question.value = `Do you want to pair create another proforma?.`
    }
}else if(invoices && invoices.length){

    message.value = 'There are already issued invoice(s) associated with this transaction.'
    question.value = `Do you want to continue and issue another ${type}?`

}else if(proformas && proformas.length){
    if(proformas.length === 1){
        message.value = 'There is an issued proforma associated with this transaction.'
        if(type === 'invoice'){
            question.value = 'Do you want to pair the new invoice with this proforma?'
            pairOn.value = true
            parentFile.value = proformas[0]
        }else if(type === 'proforma'){
            question.value = 'Do you want to issue another proforma?'
        }

    }
    else{
        // there are more proformas
        message.value = 'There are already issued proformas associated with this transaction.'
        question.value = `
                Do you want to pair your new invoice with any proforma? <br>
                If so, select desired proforma first.`
        pairOn.value = true
        // todo
    }

}

function selectFile({row, data}){
    utilities.deselectAllRows(data, row)
    row.selected = !row.selected
    parentFile.value = row.selected?row:null
}

function yes(){
    Object.assign(props.invoicingDialog, {
        hook: true,
        open: false,
    })
}

function pair(){
    if(!parentFile.value){
        error.value = 'Select row to pair first'
    }
    else{
        Object.assign(props.invoicingDialog, {
            hook: true,
            open: false,
            parentFile: parentFile.value
        })
    }
}

function no(){
    Object.assign(props.invoicingDialog, {
        hook: false,
        open: false
    })
}

function close(){
    no()
    emit('close')
}

</script>

<template>
    <DialogBase
        :dialog="{
            ...invoicingDialog,
            message
        }"
        @close="close"
    >

        <div class="data-tab">
            <SpaceBuilder
                :attrs="{
                    service: 'file',
                    space: 'file',
                    grid: 'inactive',
                    loaderType: 'all'
                }"
                :pre-fetched-data="files"
                @catchRowClicked="selectFile"
            />
        </div>

        <h3 v-html="question"></h3>

        <button data-type="prompt" class="btn__prompt green" v-on="pairOn?{click: pair}:{click: yes}">Yes</button>
        <button data-type="prompt" class="btn__prompt" :class="pairOn?'silver':'red'"  v-on="pairOn?{click: yes}:{click: no}">No</button>
        <button v-if="pairOn" data-type="prompt" class="btn__prompt red"  @click="no">Cancel</button>

        <div class="error-label" v-if="error">
            <span v-html="error"></span>
        </div>

    </DialogBase>
</template>

<style lang="scss">

.dialog .invoicing-dialog {
    .space.file{
        margin-top: 20px;

        tbody tr{
            cursor: pointer;
        }
    }

    .error-label{
        margin-top: 10px;
        margin-bottom: 0;
    }
}

</style>