<template>

    <span>Crew:</span>
    <select v-model="env.crew_id">
        <option v-for="crew in env.configuration['crew']" :value="crew.id">{{crew.name}}</option>
    </select>

    <span>Company:</span>
    <select v-model="env.company">
        <option value=""></option>
        <option v-for="company in env.configuration['company']" :value="company.id">{{company.name}}</option>
    </select>

    <span>Year:</span>
    <select v-model="env.volume">
        <option v-for="volume in volumes" :value="volume">{{volume}}</option>
    </select>

    <span>Member: </span>
    <span class="username">{{env.configuration.user['member_name']}}</span>

    <span>User: </span>
    <span class="username">{{env.configuration.user.mail}}</span>
    <a @click="signOut">[logout]</a>


</template>

<script>

import {volumes, css} from "@/app/Settings";
import {inject, reactive, ref, watchEffect} from "vue";
import { useGapi } from 'vue-gapi' // works with gapi as well

export default {
    name: "UpperMenu",
    setup(props){
        /**
         * @type {SmartEnv}
         */
        const env = inject('env')

        const gapi = useGapi()

        /** @type UserService */
        const userService = inject('UserService')

        function signOut(){
            userService.logout()
        }

        return {env, volumes, signOut}
    }
}
</script>

<style scoped lang="scss">

.upper-menu{

    a{
        margin-right: 10px;
        &:hover{
            color: darken(#f0f0f0, 10);
            cursor: pointer;
        }
    }

    font-size: 12px;

    span{
        text-transform: uppercase;
        color: cadetblue;
    }

    select{
        border: 1px solid $lightGray;
        font-size: 14px;
        padding: 3px;
        background-color: transparent;
        color: $lightGray;
        height: 25px;
        margin-left: 5px;
        margin-right: 10px;
    }

    option{
        background-color: #262626;
    }

    select:focus,
    select:focus-visible,
    option:focus,
    option:focus-visible{
        outline-style: none;
    }

    .username{
        text-transform: unset;
        color: $lightGoldOrange;
        padding-right: 10px;
    }

}


</style>
