<template>
     <SpaceBuilder
         ref="spaceBuilder"
         :attrs="attrs"
         @run="catchRun"
     />

    <InvoicingStatuses
        v-if="transactions&&invoicingStatuses.open"
        :transactions="transactions"
        :invoicingStatuses="invoicingStatuses"
        @close="closeInvoicingStatuses"
        @openFile="openFile"
    />

    <InvoicingDialog
        v-if="invoicingDialog.open"
        :invoicingDialog="invoicingDialog"
        @close="invoicingDialog.open = false"
    />

</template>

<script>


import SpaceBuilder from "@/components/space/SpaceBuilder.vue";
import InvoicingStatuses from "@/components/dialog/InvoicingStatuses.vue";
import InvoicingDialog from "@/components/dialog/InvocingDialog.vue";
import {inject, reactive, ref, watch, watchEffect} from "vue";
export default {
    name: "InvoicingView.vue",
    components: {SpaceBuilder, InvoicingStatuses, InvoicingDialog},
    props: [
        'attrs'
    ],
    setup(props){

        /** @type SmartEnv */
        const env = inject('env')

        /** @type Utilities */
        const utilities = inject('Utilities')

        /** @type Dialog */
        const dialog = inject('Dialog')

        /** @type TransactionService */
        const transactionService = inject('TransactionService')

        /** @type DriveService */
        const driveService = inject('DriveService')

        /** @type InvoicingService */
        const invoicingService = inject('InvoicingService')

        /** @type FileService */
        const fileService = inject('FileService')

        let spaceBuilder = ref()
        let invoicingStatuses = reactive({})
        let invoicingDialog = reactive({})
        let transactions = ref()
        let toggleRows = ref(false)

        const obligatedProperties = [
            'transaction_company_id',
            'transaction_bank_account_id',
            'transaction_amount_inc_vat',
            'transaction_item',
            //'contact_country_id',
            //'contact_street_name',
            //'contact_building_number',
            //'contact_city_name',
            //'contact_postal_zone',
            //'company_country_id',
            //'company_street_name',
            //'company_building_number',
            //'company_city_name',
            //'company_postal_zone',
        ];

        const methods = {
            selectRowsForInvoicing(){
                toggleRows.value = !toggleRows.value
                spaceBuilder.value.data.shown.forEach(row=>{
                    if(row.status==='for-invoicing') row.selected = toggleRows.value
                })
                if(toggleRows.value && !utilities.getSelectedRows(spaceBuilder.value.data).length) {
                    dialog.error('There are no transactions for invoicing')
                    toggleRows.value = false
                }
            },

            issueInvoice({attrs}){
                issueInvoices(attrs, 'invoice')
            },

            issueProforma({attrs}){
                issueInvoices(attrs, 'proforma')
            }
        }

        function issueInvoices(attrs, type = 'invoice'){
            if(!utilities.isAnyRowSelected(spaceBuilder.value.data)){
                dialog.error('No rows selected')
                return
            }

            let data = {
                type
            }

            transactions.value = utilities.getSelectedRows(spaceBuilder.value.data)
            invoicingStatuses.open = true;

            (function issueInvoice(index = 0){
                let transaction = transactions.value[index]
                transaction.class = 'loading'
                checkForObligatedProperties(transaction)
                    .then((transaction)=>{
                        getExistingInvoices(transaction.id)
                            .then((files)=>{
                                // there are invoices or proformas
                                if(files){

                                    let invoices = files.filter(file => file.fileType === 1)
                                    let proformas = files.filter(file => file.fileType === 4)
                                    function dialoger(){

                                        Object.assign(invoicingDialog, {
                                            open: true,
                                            files,
                                            invoices,
                                            proformas,
                                            type: 'invoicing-dialog',
                                            documentType: type,
                                            hook: undefined
                                        })

                                        let hooker = watch((invoicingDialog), function({hook}){
                                            if(hook !== undefined){
                                                if(hook) {
                                                    createInvoice(invoicingDialog.parentFile)
                                                }else {

                                                    transaction.class = 'cancelled'
                                                    dialog.close()
                                                    next()
                                                }
                                                hooker()
                                            }
                                        })
                                    }
                                    // there are existing invoices or proformas
                                    if((invoices && invoices.length) || (proformas && proformas.length)){
                                        dialoger()
                                    }
                                }
                                else{
                                    createInvoice()
                                }
                            })
                    })



                function createInvoice(parentFile = null){
                    if(parentFile) data.parentFileId = parentFile.id
                    transaction.class = 'creating'
                    invoicingService.createInvoice(transaction.id, data)
                        .then((response)=>{ // todo return transaction in response

                            driveService.relocateFile(attrs, response.item.id) // do not wait for response
                                .catch((e)=>dialog.nonCritical(e))

                            transactionService.getOne(transaction.id)
                                .then((item)=>{
                                    Object.assign(transaction, item)
                                    transaction.failMessage = null
                                    transaction.selected = false
                                    transaction.class = 'success'
                                    transaction.invoice = response.item
                                })
                                .catch((e)=>console.log(e))

                        })
                        .catch((e)=>{
                            transaction.class = 'failed'
                            transaction.failMessage = e.message
                        })
                        .finally(()=>{
                            next()
                        })
                }

                function next(){
                    index++;
                    if(index<transactions.value.length){
                        issueInvoice(index);
                    }
                }

            })();

        }

        function closeInvoicingStatuses(){
            invoicingStatuses.open = false
            invoicingStatuses.message = null
        }

        function openFile(transaction){
            spaceBuilder.value.run({method: 'openFile', row: transaction.invoice})
        }

        /**
         * Check for missing properties
         * @param transaction
         * @return {Promise<unknown>}
         */
        function checkForObligatedProperties(transaction){
            return new Promise(resolve=>{
                let missingProperties = obligatedProperties.filter(property=>transaction[property]==='' || transaction[property]===null)
                let message = `Transaction ${transaction.id} is missing values: `
                missingProperties.forEach(property=>{
                    let propertyName = utilities.getColumnName({space: 'transaction'}, property)
                    message += '<span class="list">' + propertyName + '</span>'
                })
                if(missingProperties.length) throw new Error(message)
                resolve(transaction)
            })
        }

        function getExistingInvoices(id){
            return fileService.getTransactionIssuedInvoices(id, props.attrs)
        }
        function catchRun({method, attrs}){
            methods[method]({attrs})
        }

        return {catchRun, spaceBuilder, invoicingStatuses, invoicingDialog, transactions, closeInvoicingStatuses, openFile} // must include ref spaceBuilder as well
    }
}
</script>

<style lang="scss">

.invoicing{

    margin-top: 0;

    .transaction_due_days{
        $width: 35px;
        @include assignValue(width max-width min-width, $width);
    }
}

</style>
