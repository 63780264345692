import $ from "jquery";
import vue3GoogleLogin, {googleSdkLoaded} from "vue3-google-login";
import {gapiScopes} from "@/app/Settings";

export default{
    install: (app)=>{

        /**
         * @class
         * @name Google
         * @returns {Promise}
         */
        class Google{

            user = class {

                /**
                 * Token login to google account
                 * @return {Promise<unknown>}
                 */
                static tokenLogin(){
                    return new Promise((resolve, reject)=>{
                        googleSdkLoaded((google) => {
                            google.accounts.oauth2.initTokenClient({
                                client_id: process.env.VUE_APP_GAPI_CLIENT_ID,
                                scope: gapiScopes,
                                callback: (response) => {
                                    app.env.google.token = response.access_token
                                    resolve(response)
                                },
                                error_callback: (e)=>{
                                    reject(e)
                                }
                            }).requestAccessToken()
                        })
                    })
                }

                static getUserProfile(){
                    return new Promise((resolve, reject)=>{
                        $.ajax({
                            url: 'https://www.googleapis.com/oauth2/v1/userinfo?alt=json',
                            dataType: 'json',
                            type: 'GET',
                            accept: "application/json; charset=utf-8",
                            headers:{
                                Accept: "application/json; charset=utf-8",
                                Authorization: 'Bearer ' + app.env.google.token
                            },
                            success: function (data) {
                                resolve(data)
                            },
                            error: function (e) {
                                console.log('error', e)
                                reject(e)
                            }
                        });
                    })

                }


            }

            scripts = class {

                static run(attrs, data = null){
                    return new Promise((resolve, reject)=>{
                        app.utilities.injectGapiSetting(attrs)
                        let pack = app.utilities.createPackObject(attrs, data);
                        (function call(){
                            $.ajax({
                                url: `https://script.googleapis.com/v1/scripts/${process.env.VUE_APP_GAPI_DEPLOYMENT_ID}:run`,
                                dataType: 'json',
                                contentType: 'application/json',
                                type: 'POST',
                                data: JSON.stringify({
                                    function: attrs.script,
                                    parameters: [pack]
                                }),
                                headers:{
                                    Authorization: 'Bearer ' + app.env.google.token
                                },
                                success: function (response) {
                                    if (response.error) {
                                        //API funguje, ale script vratil error
                                        let error = response.error.details[0].errorMessage;
                                        reject(error)
                                    } else {
                                        //script probehl v poradku
                                        resolve(response.response.result);
                                    }
                                },
                                error: function ({responseJSON: result}) {
                                    if(result.error.code === 401){
                                        // Not autorized, auth again
                                        app.google.user.tokenLogin()
                                            .then(call)
                                            .catch(reject)
                                    }
                                    else if (result.error) {
                                        reject(result.error)
                                    } else {
                                        // Kdyz nevime, co vyhodil,
                                        // vyhodime nespecifikovanou chybu.
                                        reject('Unknown error in Google API execution.')
                                    }

                                }
                            });
                        })();


                    })

                }

            }

            drive = class {

                static files = class{

                    static create(attrs, data, file){

                        return new Promise((resolve, reject)=>{
                            let gapiDumpFolder = app.env.configuration['gapi']['dumpFolder']
                            if(!gapiDumpFolder) throw new Error('Missing Dump folder id.</br> Please go to settings > GAPI or contact your crew manager.');

                            function uploadFile(data, metadata){
                                let multipartRequestBody =
                                    delimiter +
                                    'Content-Type: application/json\r\n\r\n' +
                                    JSON.stringify(metadata) +
                                    delimiter +
                                    'Content-Type: ' + metadata.mimeType + '\r\n' +
                                    'Content-Transfer-Encoding: base64\r\n' +
                                    '\r\n' +
                                    data +
                                    close_delim;

                                (function call(){
                                    $.ajax({
                                        url: `https://content.googleapis.com/upload/drive/v2/files?uploadType=multipart`,
                                        //dataType: 'json',
                                        contentType: 'multipart/mixed',
                                        type: 'POST',
                                        headers:{
                                            'Content-Type': 'multipart/mixed; boundary="' + boundary + '"',
                                            'Authorization': 'Bearer ' + app.env.google.token
                                        },
                                        data: multipartRequestBody,
                                        crossDomain: true,
                                        success: function (response) {
                                            /*let gapiDriveOwner = app.env.configuration['gapi']['driveOwner']
                                            if(gapiDriveOwner){
                                                app.google.handle.setFileOwnerAndPermission(
                                                    gapiDriveOwner,
                                                    response.id);
                                            }*/
                                            resolve(response)
                                        },
                                        error: function ({responseJSON: result}) {
                                            if(result.error.code === 401){
                                                // Not autorized, auth again
                                                app.google.user.tokenLogin()
                                                    .then(call)
                                                    .catch(reject)
                                            }
                                            else if (result.error) {
                                                reject(result.error)
                                            } else {
                                                // Kdyz nevime, co vyhodil,
                                                // vyhodime nespecifikovanou chybu.
                                                reject('Error while uploading file to DRIVE.')
                                            }
                                        }
                                    });
                                })()
                            }

                            const boundary = '-------314159265358979323846';
                            const delimiter = "\r\n--" + boundary + "\r\n";
                            const close_delim = "\r\n--" + boundary + "--";

                            if(attrs.isBase64File){
                                let contentType = 'application/octet-stream';
                                let metadata = {
                                    'title': 'test.pdf',
                                    'mimeType': contentType,
                                    'parents': [{
                                        "kind": "drive#parentReference",
                                        "id": gapiDumpFolder
                                    }]
                                };
                                uploadFile(file, metadata)
                            }
                            else{
                                let contentType = file.type || 'application/octet-stream';
                                let metadata = {
                                    'title': file.name,
                                    'mimeType': contentType,
                                    'parents': [{
                                        "kind": "drive#parentReference",
                                        "id": gapiDumpFolder
                                    }]
                                };
                                let reader = new FileReader();
                                reader.readAsBinaryString(file);
                                reader.onload = function (e) {
                                    let base64Data = btoa(reader.result);
                                    uploadFile(base64Data, metadata)
                                }
                            }
                        })

                    }

                    static open(fileId){
                        let url = "https://drive.google.com/file/d/"+fileId+"/";
                        window.open(url);
                    }
                }

                static folders = class{

                    static create(){
                        // not in use now, we are using app script to perfom this task
                        let data = {};
                        data.title = 'New Folder';
                        data.parents = [{'id':'1uCLUO9OSHdTJ-r3xv2xyx7Yas3AB9NbY'}];
                        data.mimeType = "application/vnd.google-apps.folder";
                        gapi.client.request({
                            'path': '/drive/v2/files',
                            'method': 'POST',
                            'body': JSON.stringify(data)})
                            .then((response)=>{
                                let folderId = response.result.id
                            })
                            .catch((e) => console.log(e));
                    }

                }

                static permissions = class{

                    static change(data){
                            let body = {
                                value: data.user,
                                type: 'user',
                                role: data.role
                            }
                            $.ajax({
                                url: `https://content.googleapis.com/drive/v2/files/${data.fileId}/permissions?sendNotificationEmails=false`,
                                contentType: 'application/json',
                                type: 'POST',
                                headers:{
                                    Authorization: 'Bearer ' + app.env.google.token
                                },
                                data: JSON.stringify(body),
                                crossDomain: true,
                                success: function (response) {
                                    //console.log('response', response)
                                },
                                error: function (result) {
                                    //console.log('result', result)
                                }
                            });
                    }

                }
            }

            handle = class {

                /** Upload file to Drive
                 * @param attrs Attrs data
                 * @param data file data
                 * @param file
                 */
                static uploadFile(attrs, data, file) {
                    return new Promise((resolve, reject) => {
                        app.google.drive.files.create(attrs, data, file)
                            .then((response)=>{
                                resolve(response)
                            })
                            .catch(reject)
                    })
                }

                /** Update single file name and location on Google Drive based on file data
                 * Replaced updateInvoice
                 * @param attrs Attrs data
                 * @param file capi smart file object
                 */
                static updateFile(attrs, file) {
                    return app.google.scripts.run({
                        ...attrs,
                        script: 'updateFile'
                    }, {file})
                }

                static nameAndLocateFile(attrs, file) {
                    return app.google.scripts.run({
                        ...attrs,
                        script: 'nameAndLocateFile'
                    }, {file})
                }

                /** Update multiple files name and location on Google Drive based on transaction data
                 * @param attrs Attrs data
                 * @param parent parent smart object
                 */
                static updateFiles(attrs, parent) {
                    return app.google.scripts.run({
                        ...attrs,
                        script: 'updateFiles'
                    }, {parent})
                }

                /**
                 * Update project Drive folder
                 * @param attrs
                 * @param project
                 * @return {Promise<unknown>}
                 */
                static updateProject(attrs, project) {
                    return app.google.scripts.run({
                        ...attrs,
                        script: 'updateProject'
                    }, {project})
                }

                static updateContact(attrs, contact) {
                    return app.google.scripts.run({
                        ...attrs,
                        script: 'updateContact'
                    }, {contact})
                }

                /** Read invoice with OCR with App scripts
                 * @param attrs
                 * @param data Transaction data (primary transaction_file_id)
                 */
                static readFileWithOcr(attrs, data) {
                    return app.google.scripts.run({
                            ...attrs,
                            script: 'readFileWithOcr'
                        },
                        data)
                }

                /** Delete File from Google Drive
                 * Cannot use Drive API directly because we want checks if it is issued file or not
                 * @param setting Setting data
                 * @param data Transaction data (primary transaction_file_id)
                 * @return Promise
                 */
                static deleteFile(setting, data) {
                    return app.google.scripts.run({
                        ...setting,
                        script: 'deleteFile'
                    }, data)
                }

                /** Delete Files from Google Drive
                 * Cannot use Drive API directly because we want checks if it is issued file or not
                 * @param setting Setting data
                 * @param ids array of transaction ids deleted
                 * @return Promise
                 */
                static deleteFiles(setting, ids) {
                    return app.google.scripts.run({
                        ...setting,
                        script: 'deleteFiles'
                    }, ids)
                }

                /** Set proper file permission and ownership on Google Drive
                 * set ownership to define use (general@separation.cz)
                 * set group of users as organizers - so they can trash file later
                 * @param driveOwner
                 * @param id
                 */
                static setFileOwnerAndPermission(driveOwner, id){
                    if(driveOwner){
                        app.google.drive.permissions.change(
                            {
                                fileId: id,
                                user: driveOwner,
                                role: 'owner'
                            });
                    }

                    // Zatim nejsme schopni dosahnou nastaeni roli organizer
                    // To jde jen u Shared Drive, ktere ale nejdou u Google Cloud basic accounts
                    /*
                    common.google.drive.permissions.insert(
                        setting,
                        {
                            fileId: data.fileId,
                            user: 'lucas@separation.cz',
                            role: 'fileOrganizer'
                        });

                     */

                }

                /** Send mail via Google Scripts API
                 * @param setting Setting data
                 * @param data Mail data.
                 */
                static sendMail(setting, data){
                    return app.google.scripts.run({
                        ...setting,
                        script: 'sendMail'
                    }, data)
                }

                static linkProjectDriveFolder(attrs, project){
                    return app.google.scripts.run({
                        ...attrs,
                        script: 'linkProjectDriveFolder'
                    }, {project})
                }

            }

            utilities = class {

            }

        }

        app.use(vue3GoogleLogin, {
            clientId: process.env.VUE_APP_GAPI_CLIENT_ID
        })

        app.google = new Google;
        app.provide('Google', new Google)
    }
}
