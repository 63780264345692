import ConfigurationRoute from "@/routes/ConfigurationRoute";
import HomeRoute from "@/routes/HomeRoute";
import TransactionRoute from "@/routes/TransactionRoute";
import BankRoute from "@/routes/BankRoute";
import CashRoute from "@/routes/CashRoute";
import FileRoute from "@/routes/FileRoute";
import ItemRoute from "@/routes/ItemRoute";
import CommentRoute from "@/routes/CommentRoute";
import ThreadRoute from "@/routes/ThreadRoute";
import ObligationRoute from "@/routes/ObligationRoute";
import RecordRoute from "@/routes/RecordRoute";
import ProjectRoute from "@/routes/ProjectRoute";
import InvoicingRoute from "@/routes/InvoicingRoute";
import SeriesRoute from "@/routes/SeriesRoute";
import VatRoute from "@/routes/VatRoute";
import UserRoute from "@/routes/UserRoute";
import BacklogRoute from "@/routes/BacklogRoute";
import ContactRoute from "@/routes/ContactRoute";
import TemplateRoute from "@/routes/TemplateRoute";
import DueRoute from "@/routes/DueRoute";
import SettingRoute from "@/routes/SettingRoute";
import TaskRoute from "@/routes/TaskRoute";
import TimerRoute from "@/routes/TimerRoute";
import DriveRoute from "@/routes/DriveRoute"
import StashRoute from "@/routes/StashRoute";
import MailRoute from "@/routes/MailRoute";

class Routes{
    static configuration = ConfigurationRoute;
    static home = HomeRoute;
    static user = UserRoute;
    static transaction = TransactionRoute;
    static bank = BankRoute;
    static cash = CashRoute;
    static file = FileRoute;
    static item = ItemRoute;
    static comment = CommentRoute;
    static thread = ThreadRoute;
    static obligation = ObligationRoute;
    static record = RecordRoute;
    static project = ProjectRoute;
    static invoicing = InvoicingRoute;
    static series = SeriesRoute;
    static vat = VatRoute;
    static contact = ContactRoute;
    static template = TemplateRoute;
    static task = TaskRoute;
    static due = DueRoute;
    static backlog = BacklogRoute;
    static setting = SettingRoute;
    static timer = TimerRoute;
    static drive = DriveRoute;
    static stash = StashRoute;
    static mail = MailRoute;
}

export default {

    get(attrs){
        let routes = new Routes[attrs.service](attrs);
        return process.env.VUE_APP_API_ROOT+routes[attrs.path];
    }

}
