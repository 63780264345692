

export default{
    install: (app)=>{

        /**
         * @name ProjectService
         */
        class ProjectService {

            /**
             * @param attrs
             * @returns {Promise<Project>}}
             */
            getOne(attrs = {}){
                return app.capi.get({
                    ...attrs,
                    service: 'project',
                    space: 'project',
                    path: 'getOne'
                })
            }

            /**
             * @param attrs
             * @return {Promise}
             */
            shareProject(attrs){
                return app.capi.create({
                    ...attrs,
                    service: 'project',
                    space: 'project',
                    path: 'share'
                })
            }

            utilities = class {


            }

        }

        app.service.project = new ProjectService
        app.provide('ProjectService', new ProjectService)

    }
}

