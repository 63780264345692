<template>
     <SpaceBuilder
         ref="spaceBuilderRef"
         :attrs="attrs"
         @run="catchRun"
         @rowsSelectionChanged="useObligationsAssistant"
     />

    <QRCodePayment
        v-if="qrPayment.open"
        :qrPayment="qrPayment"
        @update="update"
        @close="closeQrCodePaymentDialog"
    />

    <DirectPayment
        v-if="directPayment.open"
        :directPayment="directPayment"
        @update="update"
        @close="closeDirectPaymentDialog"
    />

</template>

<script>


import SpaceBuilder from "@/components/space/SpaceBuilder.vue";
import ApiRouter from "@/app/ApiRouter";
import {defineAsyncComponent, inject, reactive, ref, watch} from "vue";
export default {
    name: "ObligationView.vue",
    components: {
        SpaceBuilder,
        QRCodePayment: defineAsyncComponent(() => import('@/components/view/components/QRCodePayment.vue')),
        DirectPayment: defineAsyncComponent(() => import('@/components/view/components/DirectPayment.vue')),
    },
    props: [
        'attrs'
    ],
    setup(props){

        /** @type SmartEnv */
        const env = inject('env')

        /** @type Utilities */
        const utilities = inject('Utilities')

        /** @type Dialog */
        const dialog = inject('Dialog')

        /** @type Capi */
        const capi = inject('Capi')

        /** @type Services */
        const services = inject('Services')

        let qrPayment = reactive({open:false})
        let directPayment = reactive({open:false})
        let spaceBuilderRef = ref()

        function catchRun({method, ...rest}){
            methods[method]({method, ...rest})
        }

        function update(data){
            spaceBuilderRef.value.update(data)
        }

        const methods = {

            downloadKpcFile({data, setting, attrs}){
                let rows = utilities.getSelectedRows(data)
                if(!rows.length) return dialog.error('Please select at least two rows')
                let sendData = services.transaction.utilities.generateParamsDataFromRows(rows)
                let url = ApiRouter.get({
                    ...attrs,
                    path: 'getKpc'
                })

                services.file.downloadAndEncodeFile(attrs, url, sendData)
                    .then(()=>{
                        // show paid dialog
                        dialog.prompt('Do you want mark transactions as paid?')
                        watch((env.dialog), function({hook}){
                            if(hook){
                                for(let row of rows){
                                    update({
                                        row,
                                        update: ref({
                                            transaction_invoice_status_type_id: 'paid'
                                        }),
                                        attrs,
                                        setting,
                                        done: ()=>{
                                            row.selected = false
                                        }
                                    })
                                }
                            }
                            else{
                                dialog.close()
                            }
                        })
                    })
                    .catch((e)=>{
                        dialog.error(e)
                    })

            },

            directPayment({data, setting, attrs}){
                let rows = utilities.getSelectedRows(data)
                if(!rows.length) return dialog.error('Please select at least two rows')
                let stuck = false
                rows.forEach((row)=>{
                    // todo check other properties
                    if(!row['contact_account_number'] && row['contact_account_count']){
                        stuck = true
                        services.contact.getContactAccounts(row['contact_id'])
                            .then((accounts)=>{
                                if(!row.alerts) row.alerts = {}
                                row.alerts['transaction_supplier_contact_id'] = {
                                    type: 'select-contact-account',
                                    updated: {
                                        accounts
                                    }
                                }
                            })
                    }
                    else{
                        // todo add alert
                    }
                })

                if(stuck) return

                let sendData = services.transaction.utilities.generateParamsDataFromRows(rows)
                dialog.loading('Pushing payments to bank API')
                capi.update({
                    ...attrs,
                    path: 'directPayments'
                }, sendData)
                    .then((results)=>{
                        dialog.close()
                        directPayment.results = results
                        directPayment.setting = setting
                        directPayment.attrs = attrs
                        directPayment.rows = rows
                        directPayment.open = true
                    })
                    .catch(dialog.error)
            },

            payByQRCode({contextMenu}){
                Object.assign(qrPayment, contextMenu)
                qrPayment.open = true
                contextMenu.open = null
            }

        }

        function closeQrCodePaymentDialog(){
            qrPayment.open = false
            utilities.deselectAllRows(spaceBuilderRef.value.data)
        }

        function closeDirectPaymentDialog(){
            directPayment.open = false
            useObligationsAssistant()
            //utilities.deselectAllRows(spaceBuilderRef.value.data)
        }

        function useObligationsAssistant(){

            let rowsSelected = spaceBuilderRef.value.data.shown.filter(row=>row.selected)
            //console.log('useObligationsAssistant', env)
            if(rowsSelected.length){
                env.obligations = rowsSelected
            }else{
                env.obligations = []
            }
        }

        return {catchRun, spaceBuilderRef, update, closeQrCodePaymentDialog, qrPayment, directPayment, closeDirectPaymentDialog, useObligationsAssistant}
    }
}
</script>

<style lang="scss">

.space{
    &.obligation{
        margin-top: 0;
    }
}

</style>
