
export default class InvoicingRoute{

    constructor(attrs) {
        switch(attrs.space){
            default:
                this.get = attrs.space;
                this.getOne = "transaction/"+attrs["id"];
                this.update = "transaction/"+attrs["id"];
                this.filter = attrs.space+"/filter/";
                this.searchTransactions = 'transaction/search/params';
                this.createInvoice = 'invoicing/'+attrs["id"]
                this.reissueInvoice = 'invoicing/'+attrs["id"]
                break;

            case 'reccuring':
                this.get = attrs.space;
                this.getOne = attrs.space+"/"+attrs["id"];
                this.create = attrs.space;
                this.filter = attrs.space+"/filter/";
                this.update = attrs.space+"/"+attrs["id"];
                this.delete = attrs.space+"/"+attrs['id'];
                this.duplicate = attrs.space+'/duplicate/'+attrs['id'];
                break;
        }

    }

}
