<template>

    <ViewMenu v-if="hasMenu"
              :menu="menu"
              :attrs="attrs"
    />

    <component
        v-if="currentComponent"
        :is="currentComponent"
        :attrs="attrs"
    />

</template>

<script>

import {inject, reactive, ref, watch, watchEffect} from 'vue';
import ViewMenu from "@/components/view/ViewMenu.vue";
import {useRouter} from "vue-router";

export default {
    name: "View.vue",
    components: {ViewMenu},
    props: [
        'service',
        'space',
    ],

    setup(props){

        /**
         * @type {SmartEnv}
         */
        const env = inject('env')

        const router = useRouter()
        let currentComponent = ref()

        const hasMenu = ref(false)
        const defaultSpace = ref(false)
        const menu = ref([])
        let attrs = reactive({});

        watchEffect(()=>{
            attrs.service = env.service!==props.service?props.service:attrs.service // check if service was changed
            attrs.space = env.service!==props.service?props.space:attrs.space // check if service was changed
            env.service = attrs.service // must set to define active service for main (service) menu

            currentComponent.value = false

            // Resolve if space has space menu
            hasMenu.value = !!env.configuration['menu_view'].find(menu => {
                return menu.service === attrs.service;
            });

            menu.value = env.configuration['menu_view'].filter(menu => {
                return menu.service === attrs.service;
            });

            // If view has menu, try to find default space
            if(hasMenu.value){
                defaultSpace.value = menu.value.find(menu=>{
                    return menu.default;
                })
            }

            if(hasMenu.value && defaultSpace.value && !attrs.space){
                attrs.space = defaultSpace.value.space
            }
            else{
                attrs.space = attrs.space?attrs.space:attrs.service;
            }

            if(hasMenu.value) env.service===attrs.space?router.push(`/${env.service}`):router.push(`/${env.service}/${attrs.space}`)

            currentComponent.value = attrs.space+'View'
        })

        return {env, currentComponent, hasMenu, menu, attrs}

    }
}
</script>

<style>


</style>
