
export default{

    install:(app)=>{

        /**
         * @name Dialog
         */
        class Dialog{

            close(){
                console.log('dialog.close')
                app.env.dialog = false
            }

            error(message, setting = null){
                let e = message
                if(typeof message === 'object') {
                    message = message.message?message.message:e.code!==undefined&&e.code===0?'Internet connection error':'Unknown error' // Unknow error is there just in case. In next step we have another evaluation
                }
                app.env.dialog = {
                    message,
                    type: 'error',
                    setting
                }
                console.log(e)
            }

            success(message, setting = null){
                if(typeof message === 'object') message = message.message
                app.env.dialog = {
                    message,
                    type: 'success',
                    setting
                }
            }

            prompt(message = 'Continue?', reverse = false){
                app.env.dialog = {
                    message,
                    type: 'prompt',
                    strict: true,
                    hook: null,
                    reverse
                }
            }

            loading(message, setting = null){
                if(typeof message === 'object') message = message.message
                app.env.dialog = {
                    message,
                    type: 'loading',
                    setting
                }
            }

            login(){
                app.env.dialog = {
                    type: 'login',
                    strict: true
                }

            }

            selectCrew(){
                app.env.dialog = {
                    type: 'selectCrew',
                    strict: true
                }
            }

            nonCritical(e){
                console.log(e)
            }

            datetimePicker(startDate = null){
                app.env.dialog = {
                    type: 'dateTimePicker',
                    message: 'Select date range'
                }
            }

        }

        app.dialog = new Dialog
        app.provide('Dialog', new Dialog)

    }
}
