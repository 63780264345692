<template>
    <button
        v-if="(column['space_column_no_content_if_null']&&value)||!column['space_column_no_content_if_null']"
        :disabled="(value===null || value==='') && column.disabledIfNull"
        @click="catchClick"
        >
        <template v-if="column.buttonLabelFromValue">
            <template v-if="(value===null || value==='') && column.nullContent">
                {{column.nullContent}}
            </template>
            <template v-else>
                {{value}}
            </template>
        </template>
        <template v-else>
            {{ column.name }}
        </template>

    </button>
</template>

<script>
import {inject, reactive, ref, watchEffect} from "vue";

export default {
    name: "Button",
    components: {},
    emits: [
        'methodEmitted'
    ],
    props:[
        'attrs',
        'setting',
        'column',
        'value',
        'row'
    ],
    setup(props, {emit}){
        /** @type {SmartEnv} */
        const env = inject('env')

        /** @type Utilities */
        const utilities = inject('Utilities')

        /** @type {SmartColumn} */
        let column = props.column
        let options = env.configuration[column.sourceTable]

        let position = ref(10)
        let wait = ref()

        const catchClick = (e, property) => {
            // check if method exists, if not emit method name
            if(methods[column['space_column_function']]){
                methods[column['space_column_function']](e)
            }
            else{
                emit('methodEmitted', {
                    method:column['space_column_function'],
                    row: props.row,
                    e,
                    setting: props.setting,
                    attrs: props.attrs
                })
            }
        }

        const methods = {

        }

        return {env,options,catchClick,utilities,position,wait}
    }
}
</script>

<style scoped>

</style>
