

export default{
    install: (app)=>{

        /**
         * @name InvoicingService
         */
        class InvoicingService {

            /**
             * @param transactionId
             * @param data
             * @param attrs
             * @returns {Promise<Transaction>}}
             */
            createInvoice(transactionId, data, attrs = {}){
                return app.capi.create({
                    ...attrs,
                    service: 'invoicing',
                    path: 'createInvoice',
                    id: transactionId
                }, data)
            }

            reissueInvoice(fileId, attrs = {}){
                return app.capi.update({
                    ...attrs,
                    service: 'invoicing',
                    path: 'reissueInvoice',
                    id: fileId
                })
            }

            utilities = class {

            }

        }

        app.service.invoicing = new InvoicingService
        app.provide('InvoicingService', new InvoicingService)

    }
}

