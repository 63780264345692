<template>

    <NextRecordNumber
        type="invoice"
        :nextRecordNumber="nextRecordNumber"
    />

    <SpaceBuilder
        :attrs="attrs"
        @catchRowClicked="{}"
    />

</template>

<script>

import SpaceBuilder from "@/components/space/SpaceBuilder.vue";
import {inject, ref, watchEffect} from "vue";
import LoadingDots from "@/components/waiter/LoadingDots.vue";
import NextRecordNumber from "@/components/view/components/NextRecordNumber.vue";
export default {
    name: "InvoicesSeriesView.vue",
    components: {NextRecordNumber, LoadingDots, SpaceBuilder,},
    props: [
        'attrs'
    ],

    setup(props){

        /** @type SeriesService */
        const seriesService = inject('SeriesService')

        let nextRecordNumber = ref()

        watchEffect(()=>{

            nextRecordNumber.value = null

            seriesService.getUpcomingVs(props.attrs)
                .then((data)=>{
                    nextRecordNumber.value = data
                })
                .catch((e)=> {
                    nextRecordNumber.value = null
                })

        })

        return {nextRecordNumber}

    }
}
</script>

<style lang="scss">

.next-record-number-wrapper{
    margin-top: 10px;
    font-weight: bold;
    display: flex;
    flex-direction: column;
    align-content: center;
    align-items: center;

    .number-area{
        height: 20px;
        margin-top: 5px;

        .number{
            font-size: 16px;
            background-color: #00b050;
            padding: 2px 6px;
            border-radius: 3px;
        }
    }
}

.invoices-series,
.contracts-series,
.proformas-series
{

    .transaction_project_id{
        $width: 110px;
        @include assignValue(width max-width min-width, $width);
    }

}


</style>
