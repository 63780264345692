import $ from 'jquery'
import ApiRouter from "@/app/ApiRouter";

export default{
    install: (app)=>{
        class DriveService{

            uploadFile(attrs, file, fileRow, data){
                return new Promise((resolve, reject)=>{
                    let pack = app.utilities.createPackObject(attrs, data);

                    let formData = new FormData()
                    formData.append('file', file, file.name);
                    if(fileRow) formData.append('fileRowId', fileRow.id);
                    formData.append('setting', JSON.stringify(pack.setting));
                    formData.append('data', JSON.stringify(pack.data));

                    let url = ApiRouter.get(
                        {
                            ...attrs,
                            service: 'drive',
                            path: 'uploadFile'
                        }
                    );

                    $.ajax({
                        url: url,
                        type: 'POST',
                        data:  formData,
                        contentType: false,
                        cache: false,
                        processData: false,
                        xhrFields: {
                            withCredentials: true
                        },
                        success: function(response) {
                            console.log(response)
                            resolve(response.message)
                        },
                        error: function({responseJSON: error}) {
                            console.log(error)
                            reject(error)
                        }
                    });
                })

            }
            relocateFile(attrs, fileId){
                return app.capi.update(
                    {
                        ...attrs,
                        id: fileId,
                        service: 'drive',
                        path: 'relocateFile'
                    }
                )
            }
            relocateFiles(attrs, transactionId){
                return app.capi.update(
                    {
                        ...attrs,
                        id: transactionId,
                        service: 'drive',
                        path: 'relocateFiles'
                    }
                )
            }

            updateContactFolder(attrs, contactId){
                return app.capi.update(
                    {
                        ...attrs,
                        id: contactId,
                        service: 'drive',
                        path: 'updateContactFolder'
                    }
                )
            }

            createOrUpdateProjectFolder(attrs, projectId){
                return app.capi.update(
                    {
                        ...attrs,
                        id: projectId,
                        service: 'drive',
                        path: 'createOrUpdateProjectFolder'
                    }
                )
            }


        }

        app.service.drive = new DriveService
        app.provide('DriveService', new DriveService)
    }
}

