<template>

    <table v-if="Object.keys(sums).length" class="task-sums">
        <tr>
            <th>Tracked time</th>
            <td>{{ utilities.formatTime(sums['tracked_time']) }}</td>
            <th>Range</th>
            <td
                class="date-range"
                @click="dateTimePickerOpened=!dateTimePickerOpened"
            >
                {{ selectedRange }}
            </td>
            <td class="reset">
                <button
                    v-if="ranged"
                    class="btn__clear"
                    @click="changeRange()"
                >
                    R
                </button>
            </td>
        </tr>
    </table>

    <Teleport to="#app">
        <DateTimePicker
            :range="range"
            :mode="mode"
            v-if="dateTimePickerOpened"
            @reset="changeRange(); dateTimePickerOpened = false"
            @rangeChanged="changeRange"
            @close="dateTimePickerOpened = false"
        />
    </Teleport>

    <SpaceBuilder
        ref="taskViewBuilder"
        :attrs="attrs"
        @run="run"
    />
</template>

<script>
import SpaceBuilder from "@/components/space/SpaceBuilder.vue";
import DateTimePicker from "@/components/dialog/DateTimePicker.vue";
import {inject, reactive, ref, watchEffect} from "vue";
import moment from "moment";
export default {
    name: "TaskView.vue",
    components: {SpaceBuilder, DateTimePicker},
    props: ['attrs'],
    setup(props){

        const taskViewBuilder = ref()

        /** @type {Capi} */
        const capi = inject('Capi')

        /** @type {Dialog} */
        const dialog = inject('Dialog')

        /** @type {SmartEnv} */
        const env = inject('env')

        /** @type {Utilities} */
        const utilities = inject('Utilities')

        const uid = `${props.attrs.parentTable}-${props.attrs.parentId}`

        const dateTimePickerOpened = ref()

        let today = moment().format('D.M.YYYY')
        let defaultRange = `1.1.1970 - ${today}`

        let selectedRange = ref(defaultRange)

        const range = ref({
            start: null,
            end: null
        });

        const mode = ref('date')

        const ranged = ref(false)

        function run({method, ...rest}){
            if(methods[method]) methods[method](rest)
        }
        function changeRange(newRange = null){
            if(newRange?.start && newRange?.end){
                ranged.value = true
                mode.value = newRange.mode
                let start = newRange.mode==='date'?moment(newRange.start).format('D.M.YYYY'):moment(newRange.start).format('D.M.YYYY HH:mm')
                let end = newRange.mode==='date'?moment(newRange.end).format('D.M.YYYY'):moment(newRange.end).format('D.M.YYYY HH:mm')
                selectedRange.value = `${start} - ${end}`
                taskViewBuilder.value.reFetch(true, newRange, null, true)
            }
            else{
                ranged.value = false
                selectedRange.value = defaultRange
                taskViewBuilder.value.reFetch(true)
                range.value = {
                    start: null,
                    end: null
                }
            }

            getParentTasksSums(newRange)
        }

        function getParentTasksSums(range = null){
            capi.get({
                ...props.attrs,
                path: 'getParentTasksSums',
                additionalData: range,
                additionalDataPresents: range?true:null
            })
                .then((balance)=>{
                    Object.assign(sums, balance)
                })
                .catch(dialog.nonCritical)
        }

        getParentTasksSums()

        let sums = reactive({})

        watchEffect(()=>{
            env.trackers.find(tracker=>{
                if(tracker.uid===uid) {
                    tracker.total = sums
                    tracker.updateTotal = !ranged.value
                }
            })
        })

        const methods = {
            toggleTimeTracker({row, ...rest}){
                let anotherActiveTracker = env.trackers.find(tracker=>tracker.active && tracker.task.id!==row.id)
                if(anotherActiveTracker) anotherActiveTracker.active = false // stop another active tracker

                let existingTracker = env.trackers.find(tracker=>tracker.task.id===row.id)
                if(existingTracker) {
                    if(!existingTracker.open){
                        existingTracker.timer = null
                        existingTracker.open = true
                    }
                    existingTracker.active = !existingTracker.active
                } // toggle existing tracker
                else{
                    // open new tracker
                    env.trackers.push({
                        ...rest,
                        task: row,
                        uid,
                        open: true,
                        total: sums,
                        active: true
                    })

                    console.log('open new tracker', env.trackers)
                }
            }
        }

        return {
            env,
            run,
            sums,
            utilities,
            dateTimePickerOpened,
            changeRange,
            taskViewBuilder,
            selectedRange,
            range,
            mode,
            ranged
        }
    }

}
</script>

<style lang="scss">

.task-sums{
    height: 30px;
    border-collapse: collapse;
    margin: 5px auto 10px;
    align-items: center;

    th, td{
        text-align: right;
        padding: 3px 8px;

        &.negative{
            background-color: $red;
        }

        &.positive{
            background-color: $green;
        }

        &.date-range{
            width: 125px;
            text-align: center;
            cursor: pointer;
            &:hover{
                background-color: $goldOrange;
            }
        }

        &.reset{
            width: 20px;
        }
    }
}

.task{

    .task_type_id{
        $width: 100px;
        @include assignValue(width max-width min-width, $width);
    }

    .task_name{
        $width: 100px;
        @include assignValue(width max-width min-width, $width);
    }

    .tracked_time{
        text-align: right;
        $width: 60px;
        @include assignValue(width max-width min-width, $width);
    }

    .toggle_time_tracker{
        text-align: center;
        $width: 24px;
        @include assignValue(width max-width min-width, $width);
    }

}
</style>
