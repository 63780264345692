<template>

    <div class="toggle-year">
        <Toggle
            :toggle="toggle"
            @selected="selectCalculations"
        />
        <div class="info">
            <span v-if="toggle.selected==='overall'">Projects' balances are calculated from transactions from all years</span>
            <span v-if="toggle.selected==='year'">Projects' balances are calculated from {{env.volume}} transactions only</span>

        </div>

    </div>

    <SpaceBuilder
        ref="spaceBuilderRef"
        :attrs="attrs"
        @run="run"
        @catchRowClicked="{}"
        @dataFetched="watchForDataFetch"
        @dataTouched=""
    />

    <ProjectShare
        v-if="shareProjectDialog.open"
        :attrs="shareProjectDialog.attrs"
        :row="shareProjectDialog.row"
        @close="shareProjectDialog.open = false"
    />

</template>

<script>

import SpaceMenu from "@/components/space/SpaceMenu.vue";
import SpaceBuilder from "@/components/space/SpaceBuilder.vue";
import {defineAsyncComponent, inject, reactive, ref, watch, watchEffect} from "vue";
import Toggle from "@/components/elements/Toggle.vue";

export default {
    name: "ProjectView.vue",
    components: {
        Toggle,
        SpaceBuilder, SpaceMenu,
        ProjectShare: defineAsyncComponent(() => import('@/components/view/components/ProjectShare.vue')),
        DialogBase: defineAsyncComponent(() => import('@/components/dialog/DialogBase.vue'))
    },
    props: [
        'attrs'
    ],
    setup(props){

        /** @type DriveService */
        const driveService = inject('DriveService')

        /** @type Dialog */
        const dialog = inject('Dialog')

        /** @type Utilities */
        const utilities = inject('Utilities')

        /** @type SmartEnv */
        const env = inject('env')

        let spaceBuilderRef = ref()
        let spaceBuilderProjectsBalanceRef = ref()
        let shareProjectDialog = reactive({
            open: false
        })

        let toggle = reactive({
            left: {
                label: 'year',
                emit: 'year'
            },
            right: {
                label: 'overall',
                emit: 'overall'
            },
            selected: 'overall'
        })


        let attrs = props.attrs
        attrs.hideIfRestricted = true
        attrs.toggle = toggle.selected
        attrs.volume = env.volume

        class methods {

            /**
             * @param contextMenu
             * @param method
             */
            linkProjectToDrive({contextMenu, method}){
                contextMenu.handling[method] = true
                driveService.createOrUpdateProjectFolder(contextMenu.attrs, contextMenu.row.id)
                    .then((response)=>{
                        contextMenu.row['project_drive_folder_id'] = contextMenu.row.driveFolder = response.updated.driveFolder
                        dialog.success('Folder was linked:<br><a target="_blank" href="https://drive.google.com/drive/u/0/folders/'+response.updated.driveFolder+'">'+response.updated.driveFolder+'</a>')
                    })
                    .catch((e)=>{
                        dialog.error(e)
                    })
                    .finally(()=>{
                        contextMenu.open = false
                        utilities.deselectAllRows(spaceBuilderRef.value.data)
                    })
            }

            shareProject({contextMenu}){
                Object.assign(shareProjectDialog, {
                    open: true,
                    attrs: contextMenu.attrs,
                    row: contextMenu.row
                })
                shareProjectDialog.open = true
                contextMenu.open = false
                contextMenu.row.selected = false
            }

        }
        function run({e, method, contextMenu}){
            let meth = new methods()
            meth[method]({e, method, contextMenu})
        }

        function watchForDataFetch({data}){
            let filteredProjects = []
            //data.fetched.forEach((row)=>filteredProjects.push(row.id))
        }

        watch(() => env.volume, ()=>{
            if(!env.volume) {
                toggle.selected = 'overall'
                attrs.toggle = 'overall'
                attrs.volume = env.volume
            }
            else{
                attrs.volume = env.volume
            }
        })

        function selectCalculations(){
            if(toggle.selected==='year' && !env.volume){
                toggle.selected='overall'
                dialog.error('Cannot filter by year with no volume selected')
            }
            else{
                attrs.toggle = toggle.selected
                reloadProjects()
            }
        }


        function closeShareProjectDialog(){
            shareProjectDialog.open = false
        }

        function reloadProjects(){
            dialog.loading('Please wait for recalculations')
            spaceBuilderRef.value.reFetch()
                .then(()=>{
                    dialog.close()
                })
        }

        return {run,spaceBuilderRef, spaceBuilderProjectsBalanceRef, watchForDataFetch, shareProjectDialog, closeShareProjectDialog, toggle, attrs, env, selectCalculations}
    }
}
</script>

<style lang="scss">

.toggle-year{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 5px;
    //max-width: 200px;
    .toggle{
        margin-top: 5px;
    }

    .info{
        margin-top: 5px;
    }
}

.project-balance{
    margin-bottom: 2px;
    thead,
    tbody{
        tr{
            td, th{
                text-align: right;
                padding-left: 10px;
            }
        }
    }

    .name{
        min-width: 100px;
    }

}


.project
{
    .project_toggle{
        $width: 40px;
        @include assignValue(width max-width min-width, $width);
    }

    .project_years{
        $width: 80px;
        @include assignValue(width max-width min-width, $width);
    }

    .project_name{
        $width: 180px;
        @include assignValue(width max-width min-width, $width);
    }

    .project_company_id{
        $width: 100px;
        @include assignValue(width max-width min-width, $width);
    }

    .project_client_id{
        $width: 180px;
        @include assignValue(width max-width min-width, $width);
    }

    .project_status_id{
        $width: 80px;
        @include assignValue(width max-width min-width, $width);
    }

    .project_expenses,
    .project_incomes,
    .project_balance,
    .project_budget_threshold
    {
        $width: 90px;
        text-align: right;
        @include assignValue(width max-width min-width, $width);
    }

    tbody{
        tr[data-project_status_id='2']{

            td{
                background:url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAQAAAAECAYAAACp8Z5+AAAAAXNSR0IArs4c6QAAAB1JREFUGFdjZEAFDYxI/AYgGy4A5oAkQSrgHJAAAGLuA4UT0GGsAAAAAElFTkSuQmCC");
            }

            td{
                &.positive{
                    background-color: $green;
                }
                &.negative{
                    background-color: $red;
                }
            }
        }

        .project_has_file{
            button{
                background-color: $blue;
                color: $lightGray;

                &:hover{
                    background-color: lighten($blue, 15);
                }
            }
        }
    }
}




</style>
