<template>
    <div data-type="dialog" class="container" @click.self="$emit('close')">
        <div data-type="dialog" class="inner">
            <h1 data-type="dialog" ><span>Creating invoice(s)</span></h1>
            <div class="transaction"  v-for="transaction in transactions">

                <div class="desc" >
                    Transaction {{ transaction.id }}
                </div>
                <div
                    class="status"
                    :class="[
                        transaction.class?transaction.class:'pending',
                    ]"
                    v-on="transaction.failMessage?{click:()=>showFailMessage(transaction.failMessage)}:transaction.class==='success'?{click:()=>$emit('openFile', transaction)}:{}"
                >
                    <span :class="
                        [
                            transaction.class?transaction.class:'pending',
                            (transaction.class==='success'||transaction.class==='failed')&&'click'
                        ]"
                    >
                        {{ getStatusMessage(transaction.class) }}
                    </span>
                    <LoadingDots v-if="transaction.class==='creating' || transaction.class==='loading'"/>
                </div>

            </div>
            <div v-if="invoicingStatuses.message" class="message">
                <span>{{ invoicingStatuses.message }}</span>
            </div>
        </div>
    </div>
</template>

<script>

import {inject, onMounted, onUnmounted} from "vue";
import LoadingDots from "@/components/waiter/LoadingDots.vue";
import utilities from "@/plugins/Utilities";

export default {
    name: "InvoicingStatuses.vue",
    components: {LoadingDots},
    props: [
        'transactions',
        'invoicingStatuses'
    ],
    emits: [
        'close',
        'openFile'
    ],
    setup(props){

        /** @type Dialog */
        const dialog = inject('Dialog')

        /** @type Utilities */
        const utilities = inject('Utilities')

        function showFailMessage(message){
            dialog.error(message, {doNotHoldScrolling: true})
        }

        function getStatusMessage(className){

            console.log('getStatusMessage', className)

            switch (className){
                case 'success':
                    return 'created'

                case '':
                case undefined:
                    return 'pending'

                default:
                    return className
            }

        }

        onMounted(()=>{
            // To get the scroll position of current webpage
            let TopScroll = window.pageYOffset || document.documentElement.scrollTop;

            // if scroll happens, set it to the previous value
            window.onscroll = function() {
                window.scrollTo(window.scrollX, TopScroll);
            };
        })

        onUnmounted(()=>{
            window.onscroll = function() {};
        })

        let zindex = utilities.getZindex()+1

        return {close, showFailMessage, getStatusMessage, zindex}
    }
}
</script>

<style scoped lang="scss">

.container{
    position: fixed;
    top: 0;
    left: 0;
    width: 2650px;
    height: 100%;
    background: rgba(0,0,0, 0.5);
    z-index: v-bind(zindex);
}

.inner{
    position: fixed;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: cadetblue;
    border-radius: 3px;
    padding: 10px 10px 20px;
    width: 400px;

    .transaction{
        padding: 5px;
        display: flex;
        justify-content: center;

        .desc{
            flex-basis: 7rem;
        }

        .status{

            text-align: left;
            flex-basis: 7rem;

            span{
                padding: 3px;
                border-radius: 3px;

                &.click:hover{
                    cursor: pointer;
                }

                &.pending,
                &.loading,
                &.cancelled{
                    background-color: gold;
                    color: $darkGray;
                }

                &.creating{
                    background-color: $blue;
                    color: #f0f0f0;
                }

                &.failed{
                    background-color: crimson;
                    color: #f0f0f0;
                }

                &.success{
                    background-color: #00b050;
                    color: #f0f0f0;
                }
            }


        }
    }

    .message {
        margin-top: 10px;

        span{
            background-color: $lighterGray;
            border-radius: 3px;
        }

    }


}

.inner.error{
    background-color: crimson;
}

</style>
