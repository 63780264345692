<template>
    <img class="logo" src="@/assets/img/logo/capptn-logo-s-textem-white-horizontal-65px.png">
    <div class="upper-menu">
        <UpperMenu v-if="env.configuration"/>
    </div>
    <div class="main-menu">
        <ServiceMenu v-if="env.configuration"/>
    </div>
</template>

<script>
import {inject, onMounted} from "vue";

export default {
    name: "Header",
    setup(){
        const env = inject('env')

        /** @type Utilities */
        const utilities = inject('Utilities')

        onMounted(()=>{
            utilities.centerPage()
        })

        return {env}
    }


}
</script>

<style scoped>

.logo{
    position: absolute;
    left: 10px;
    top: 12px;
}

.main-menu{
    margin-top: 10px;
}
</style>
