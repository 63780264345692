
export default{

    install:(app)=>{

        /**
         * @name Context
         */
        class Context{

            close(){
                app.env.contextMenu = false
            }


        }

        app.provide('Context', new Context)

    }
}
