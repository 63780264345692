

export default{
    install: (app)=>{

        /**
         * @name TransactionService
         */
        class TransactionService {

            /**
             * @param id
             * @param attrs
             * @returns {Promise<Transaction>}}
             */
            getOne(id, attrs = {}){
                return app.capi.get({
                    ...attrs,
                    service: 'transaction',
                    space: 'transaction',
                    path: 'getOne',
                    id
                })
            }

            setContactAccount(transactionId, accountId, attrs = {}){
                console.log('transactionId', transactionId)
                return app.capi.update({
                    ...attrs,
                    service: 'transaction',
                    space: 'transaction',
                    id: transactionId
                }, {
                    transaction_contact_account_id: accountId
                })
            }

            utilities = class {

                static generateParamsDataFromRows(rows){

                    let sendData = {
                        property: [],
                        value: []
                    }

                    for(let row of rows){
                        sendData.property.push('transaction_id')
                        sendData.value.push(row.id)
                    }

                    return sendData

                }

            }

        }

        app.service.transaction = new TransactionService
        app.provide('TransactionService', new TransactionService)

    }
}

