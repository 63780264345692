
export default class ObligationRoute {

    constructor(attrs) {

        this.get = attrs.space;
        this.getOne = 'transaction/'+attrs['id'];
        this.update = 'transaction/'+attrs['id'];
        this.filter = attrs.space+'/filter/';
        this.getKpc = 'obligation/kpc/';
        this.directPayment = 'obligation/direct-payment/'+attrs['id'];
        this.directPayments = 'obligation/direct-payments/';

    }

}
